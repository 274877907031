import { createGlobalStyle } from 'styles/styled';
import ArrowIcon from 'images/icons/arrow.svg';

const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
  }

  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  body {
    background-color: rgb(0 18 49 / 1);
    font-family: 'Roboto';
    font-weight: ${({ theme }) => theme.fontWeight.light};
    line-height: 1;
  }

  @-moz-document url-prefix() {
    body {
      background-color: rgb(1, 19, 43);
    }
  }

  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  input {
    -webkit-border-radius: 0;
    border-radius: 0;
    filter: none;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }
  /* remove background color for autocomplete */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-transition: color 9999s ease-out, background-color 9999s ease-out;
    -webkit-transition-delay: 9999s;
  }

  :-moz-autofill, :-moz-autofill-preview {
    filter: grayscale(21%) brightness(88%) contrast(161%) invert(10%) sepia(40%) saturate(206%);
  }

  b, strong {
    font-weight: 700;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  h1, h2, h3, p {
    strong {
      font-weight: ${({ theme }) => theme.fontWeight.bold};
    }
  }

  h1 {
    font-size: 48px;
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    letter-spacing: 0.5px;
    line-height: 42px;
    letter-spacing: 0.46px;

    @media ${({ theme }) => theme.devices.medium} {
      font-size: 132px;
      letter-spacing: 1.26px;
      line-height: normal;
    }
  }

  h2 {
    font-style: normal;
    color: ${({ theme }) => theme.palette.light};
    font-size: 26px;
    letter-spacing: 0.13px;
    line-height: 1.08;
    margin-bottom: 6px;

    @media ${({ theme }) => theme.devices.medium} {
      margin-bottom: 0;
      font-weight: ${({ theme }) => theme.fontWeight.bold};
      font-size: ${({ theme }) => theme.fontSize.title};
      letter-spacing: 0.23px;
      line-height: normal;
    }
  }

  h3 {
    color: ${({ theme }) => theme.palette.light};
    font-size: ${({ theme }) => theme.fontSize.label};
    letter-spacing: 0.13px;
    line-height: 1.1;

    @media ${({ theme }) => theme.devices.medium} {
      font-size: 24px;
      line-height: 25px;
      letter-spacing: 0.23px;
    }
  }

  h4 {
    color: ${({ theme }) => theme.palette.light};
    font-size: ${({ theme }) => theme.fontSize.label};
    letter-spacing: 0.13px;
    line-height: 1.1;

    @media ${({ theme }) => theme.devices.medium} {
      font-size: ${({ theme }) => theme.fontSize.contentText};
      letter-spacing: 0.23px;
    }
  }

  img {
    max-width: 100%;
    vertical-align: middle;
    width: 100%;
  }


  /* Swiper.js */
  .swiper-container {
    min-height: 350px;
    overflow: visible;

    @media ${({ theme }) => theme.devices.medium} {
      overflow: hidden;
    }

    @media ${({ theme }) => theme.devices.large} {
      min-height: 700px;
    }
  }

  .swiper-button-prev, .swiper-button-next {
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    top: 125px;
    transition: ${({ theme }) => theme.ui.transition('opacity')};

    &:hover {
      opacity: ${({ theme }) => theme.ui.opacity()};
    }

    &:after {
      display: none;
    }

    @media ${({ theme }) => theme.devices.medium} {
      width: 54px;
      height: 54px;
      top: 400px;
    }

    @media (min-height: 1080px) {
      top: 500px;
    }

    @media (min-height: 1440px) {
      top: 600px;
    }
  }

  .swiper-button-next {
    background-image: url(${ArrowIcon});
    right: -20px;
    height: 24px;
    width: 24px;
    top: 110px;

    @media ${({ theme }) => theme.devices.medium} {
      right: 0;
      height: 36px;
      width: 36px;
    }

    @media (min-width: 950px){
      top: 400px;
      height: 53px;
      width: 53px;
    }
  }

  .swiper-button-prev {
    background-image: url(${ArrowIcon});
    transform: rotate(180deg);
    left: -20px;
    height: 24px;
    width: 24px;
    top: 110px;

    @media ${({ theme }) => theme.devices.medium} {
      left: 0;
      height: 36px;
      width: 36px;
    }

    @media ${({ theme }) => theme.devices.large} {
      top: 400px;
      height: 53px;
      width: 53px;
    }

    @media (min-width: 950px){
      top: 400px;
      height: 53px;
      width: 53px;
    }
  }

  .swiper-button-disabled {
    display: none;
  }

  // It's a hack to be able to display gatsby-image
  // components inside the image modal on widths < 900px.
  #portal {
    div[aria-hidden="true"] {
      padding-bottom: 180% !important;
    }
  }
`;

export default GlobalStyle;
