import * as styledComponents from 'styled-components';

type DevicesSize = {
  xsmall: number;
  small: number;
  medium: number;
  large: number;
  xlarge: number;
  xxlarge: number;
};

const deviceSize = {
  xsmall: 320,
  small: 576,
  medium: 768,
  large: 992,
  xlarge: 1200,
  xxlarge: 1440,
};

type Devices = {
  xsmall: string;
  small: string;
  medium: string;
  large: string;
  xlarge: string;
  xxlarge: string;
};

const devices = {
  xsmall: `(min-width: ${deviceSize.xsmall}px)`,
  small: `(min-width: ${deviceSize.small}px)`,
  medium: `(min-width: ${deviceSize.medium}px)`,
  large: `(min-width: ${deviceSize.large}px)`,
  xlarge: `(min-width: ${deviceSize.xlarge}px)`,
  xxlarge: `(min-width: ${deviceSize.xxlarge}px)`,
};

type Dimensions = {
  contentMaxWidthBig: number;
  contentMaxWidthMedium: number;
  spacingBase10: number;
};

const dimensions = {
  contentMaxWidthBig: 1080,
  contentMaxWidthMedium: 756,
  spacingBase10: 10,
};

type FontSize = {
  baseMobile: string;
  label: string;
  base: string;
  labelMedium: string;
  contentText: string;
  copy: string;
  titleMobile: string;
  labelBig: string;
  title: string;
  pageTitleMobile: string;
  pageTitle: string;
};

const fontSize = {
  baseMobile: '12px',
  label: '14px',
  base: '15px',
  labelMedium: '16px',
  contentText: '20px',
  copy: '24px',
  titleMobile: '26px',
  labelBig: '28px',
  title: '46px',
  pageTitleMobile: '48px',
  pageTitle: '130px',
};

type FontWeight = {
  light: number;
  regular: number;
  bold: number;
};

const fontWeight = {
  light: 300,
  regular: 400,
  bold: 700,
};

type Gradient = {
  primary: string;
  backgroundMobile: string;
  backgroundDesktop: string;
};

const gradient = {
  primary: `linear-gradient(to top, #0090a3 20%, #00d8bc 92%)`,
  backgroundMobile: `radial-gradient(circle at 50% 41%, #002c62, #00132d 48%)`,
  backgroundDesktop: `radial-gradient(circle at 50% 52%, #002c62, #00132d 114%)`,
};

type Palette = {
  primary: string;
  primaryLight: string;
  secondary: string;
  secondaryLight: string;
  dark: string;
  mediumDark: string;
  light: string;
  lightDark: string;
  backgroundPrimary: string;
  backgroundSecondary: string;
  backgroundVideo: string;
};

const palette = {
  primary: '#0090a3',
  primaryLight: '#00d8bc',
  secondary: '#096b8d',
  secondaryLight: '#cfe8eb',
  dark: '#000000',
  mediumDark: '#5d6061',
  light: '#ffffff',
  lightDark: 'rgba(255, 255, 255, 0.69)',
  backgroundPrimary: '#00132d',
  backgroundSecondary: '#002c62',
  backgroundVideo: '#030b16',
};

type UI = {
  border(color?: string): string;
  borderRadius: string;
  opacity(value?: string): string;
  transition(property: string, multiplier?: number): string;
};

const ui = {
  border: (color = palette.light) => `2px solid ${color}`,
  borderRadius: '25px',
  transition: (prop: string, multiplier = 1) =>
    `${prop} ${multiplier * 200}ms ease-in`,
  opacity: (value: string) => `${value || 0.3}`,
};

export interface ThemeInterface {
  deviceSize: DevicesSize;
  devices: Devices;
  dimensions: Dimensions;
  fontSize: FontSize;
  fontWeight: FontWeight;
  gradient: Gradient;
  palette: Palette;
  ui: UI;
}

export const theme: ThemeInterface = {
  deviceSize,
  devices,
  dimensions,
  fontSize,
  fontWeight,
  gradient,
  palette,
  ui,
};

const {
  default: styled,
  css,
  createGlobalStyle,
  keyframes,
  ThemeProvider,
  useTheme,
} = styledComponents as styledComponents.ThemedStyledComponentsModule<
  ThemeInterface
>;

export { css, createGlobalStyle, keyframes, ThemeProvider, useTheme };
export default styled;
